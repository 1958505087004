<template>
  <el-container style="overflow:hidden" class="wrap" @click.native="visible = false">
    <i class="el-icon-error" v-if="curRouter!=='/home/camera'"
       style="color:#ffb32f;font-size:16px;position:absolute;right:5px;top:5px;cursor:pointer;" @click="logout"></i>
    <el-header class="home-header">
      <!-- 头部菜单 -->
      <div class="header-menu">
        <!-- <scroll-view style="justify-content:flex-start"> -->
        <el-menu class="el-menu-demo" mode="horizontal" :default-active="currentMenu">
          <el-menu-item v-for="(item,i) in firstMentList" :key="i" :index="item.text" @click="initMenu(i,item)">
            <span slot="title">{{ item.text }}</span>
          </el-menu-item>
        </el-menu>
        <!-- </scroll-view> -->
      </div>
      <div class="home-title">
        <img src="../assets/img/logo.png" alt="" width="38px" height="38px">
        <!-- <span>捷信云物联网大数据服务平台</span> -->
        <span>{{ homeTitle }}</span>
      </div>
      <!-- 头部菜单 -->
      <div class="header-menu">
        <!-- <scroll-view style="justify-content:flex-end"> -->
        <el-menu class="el-menu-demo" mode="horizontal" :default-active="currentMenu">
          <el-menu-item v-for="(item,i) in secondMentList" :key="i" :index="item.text" @click="initMenu(i,item)">
            <span slot="title">{{ item.text }}</span>
          </el-menu-item>
        </el-menu>
        <!-- </scroll-view> -->
      </div>
      <div id="clock">
        <span class="date">{{ date }}</span>
        <span class="time">{{ time }}</span>
        <span class="role">{{ roleName }}</span>
      </div>
      <!--             <el-dropdown v-if="curRouter!=='/home/camera'">
                    <el-avatar size="small" :src="avatar"></el-avatar><i class="el-icon-arrow-down el-icon&#45;&#45;right" style="color:#fff"></i>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item icon="el-icon-user">{{userName}}</el-dropdown-item>
                      <el-dropdown-item icon="el-icon-s-custom">{{roleName}}</el-dropdown-item>
                      <el-dropdown-item @click.native="pwdShow=true" icon="el-icon-refresh">修改密码</el-dropdown-item>
                      <el-dropdown-item @click.native="logout" icon="el-icon-error">退出登陆</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>-->
    </el-header>
    <!-- 左侧菜单 -->
    <el-container style="height:calc(100vh - 76px)">
      <el-container class="bottom" direction="horizontal">
        <el-aside :width="isCollapse?'64px':'200px'" class="home-aside" v-show="show">
          <div class="toggle" @click="isCollapse = !isCollapse">
            <i :class="isCollapse?'el-icon-s-unfold':'el-icon-s-fold'"></i>
          </div>
          <SubMenu :menu-list="menuList2" :props="defaultProps" @getmenu="onGetMenu" :isCollapse="isCollapse" />
        </el-aside>
        <el-container direction="vertical">
          <div class="tabs_box" v-if="curRouter!=='/home/camera'">
            <div class="arrow el-icon-d-arrow-left" @click="arrow('ins')"></div>
            <div class="tabs" ref="tabs">
              <div class="tabs_item" :class="{ 'active': activePath === '/' || activePath === '/home/GIS' }"
                   :id="'tabs_item' + '-1'" v-if="roleId!==12" @contextmenu.prevent="openMenu('/home/GIS', -1, $event)"
                   @click="toPage('/home/GIS', -1)"><i class="el-icon-s-home"></i> 首页
              </div>
              <div class="tabs_item" :class="{ 'active': activePath === '/' || activePath === '/home/unit/home' }"
                   :id="'tabs_item' + '-1'" v-if="roleId===12"
                   @contextmenu.prevent="openMenu('/home/unit/home', -1, $event)"
                   @click="toPage('/home/unit/home', -1)"><i class="el-icon-s-home"></i> 首页
              </div>
              <div class="tabs_item" :id="'tabs_item' + index"
                   :class="{ 'active': (activePath === item.path)||activePath.indexOf(item.path)!==-1 }"
                   v-for="(item, index) in tabsList" :key="index"
                   @contextmenu.prevent="openMenu(item.path, index, $event)" @click="toPage(item.path, index)"
                   @mouseover="moveIndex = index" @mouseleave="moveIndex = ''">
                <span> {{ item.text }} </span>
                <span class="close" @click.stop="closeEvent('self-close', index)"><i class="el-icon-close"></i></span>
              </div>
            </div>
            <div class="arrow el-icon-d-arrow-right" @click="arrow('add')"></div>
            <!-- 右键菜单栏 -->
            <div class="menu_box" v-show="visible" :style="{left: MenuLeft + 'px', top: MenuTop + 'px'}">
              <div class="menu" :class="{ 'disabled': activeIndex === -1 }"
                   @click="activeIndex === -1 ? '' : closeEvent('self')">
                <i class="iconfont icon-Eliminate" style="margin-right: 10px"></i> 关闭当前标签
              </div>
              <div class="menu" :class="{ 'disabled': activeIndex === 0 || activeIndex === -1 }"
                   @click="activeIndex === 0 || activeIndex === -1 ? '' : closeEvent('left')">
                <i class="iconfont icon--zuofudong" style="margin-right: 10px"></i> 关闭左侧标签
              </div>
              <div class="menu" :class="{ 'disabled': activeIndex === tabsList.length - 1 }"
                   @click="activeIndex === tabsList.length - 1 ? '' : closeEvent('right')">
                <i class="iconfont icon--zuofudong-copy" style="margin-right: 10px"></i> 关闭右侧标签
              </div>
              <div class="menu" :class="{ 'disabled': tabsList.length <= 1 || activeIndex === -1}"
                   @click="tabsList.length <= 1 || activeIndex === -1 ? '' : closeEvent('other')">
                <i class="iconfont icon-ziyuan219" style="margin-right: 10px"></i> 关闭其它标签
              </div>
              <div class="menu" :class="{ 'disabled': tabsList.length <= 1 }"
                   @click="tabsList.length <= 1 ? '' : closeEvent('all')">
                <i class="iconfont icon-suoyouzhijieguidangjilu" style="margin-right: 10px"></i> 关闭所有标签
              </div>
            </div>
          </div>
          <el-main :style="show||isTable?'padding:20px':'padding:0'">
            <keep-alive>
              <!-- 需要缓存的视图组件 -->
              <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
            <!-- 不需要缓存的视图组件 -->
            <router-view v-if="!$route.meta.keepAlive"></router-view>
          </el-main>
        </el-container>
      </el-container>
      <div class="footer-wrap">
        <span>江西捷点科技有限公司监制</span>
        <span>备案号 120.55.61.249</span>
        <a href="https://xsw-xf.oss-cn-hangzhou.aliyuncs.com/xiaofang/VideoWebPlugin.exe" target="_blank">下载视频插件</a>
      </div>
    </el-container>
    <!-- 行政区域弹窗 -->
    <el-dialog title="修改密码" :visible.sync="pwdShow" width="30%" @close="clear" v-dialogDrag
               :close-on-click-modal="false">
      <el-form :model="pwdForm" ref="pwdForm" :rules="rules">
        <el-form-item label="旧密码" prop="oldPassword" :label-width="formLabelWidth">
          <el-input v-model="pwdForm.oldPassword" autocomplete="off" prefix-icon="el-icon-lock" placeholder="请输入旧密码"
                    show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword" :label-width="formLabelWidth">
          <el-input v-model="pwdForm.newPassword" autocomplete="off" prefix-icon="el-icon-lock" placeholder="请输入新密码"
                    show-password></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="pwdShow = false">取 消</el-button>
        <el-button type="primary" @click="updatePassword">修 改</el-button>
      </div>
    </el-dialog>
    <div class="alarm-badge" @click="store.showAudio=!store.showAudio" v-if="curRouter!=='/home/camera'">
      <i class="el-icon-bell" v-show="store.showAudio"></i>
      <i class="el-icon-close-notification" v-show="!store.showAudio"></i>
    </div>
  </el-container>
</template>

<script>
import SubMenu from '../components/SubMenu/SubMenu.vue'
import _ from 'lodash'
import store from "./GIS/store"
export default {
  components: {
    SubMenu
  },
  data() {
    return {
      store,
      show: false,
      // 判断是否为表格界面
      isTable: false,
      roleName: '',
      roleId: 0,
      userName: '',
      defaultProps: {
        icon: 'icon',
        children: 'children',
        text: 'title',
        id: 'path'
      },
      isCollapse: false,
      formLabelWidth: '100px',
      // 菜单列表
      menuList: [],
      menuList2: [],
      firstMentList: [],
      secondMentList: [],
      currentMenu: '',
      time: '',
      date: '',
      week: ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      clock: null,
      pwdShow: false,
      pwdForm: {},
      pwdFormCopy: {},
      rules: {
        oldPassword: [
          {required: true, message: '请输入旧密码', trigger: 'blur'}
        ],
        newPassword: [
          {required: true, message: '请输入新密码', trigger: 'blur'}
        ]
      },
      routerShow: true,
      curRouter: '',
      audioObj: null,
      alarmTotal: 0,
      timeMachine: null,
      tabsList: [],
      activePath: '/',
      activeIndex: '',
      MenuLeft: 0,
      MenuTop: 0,
      moveIndex: 0,
      visible: false,
      homeTitle: ''
    }
  },
  watch: {
    '$route.path': 'changeRoute',
  },
  destroyed() {
    clearInterval(this.timeMachine)
    this.timeMachine = null
  },
  created() {
    this.audioObj = new Audio()
    this.audioObj.src = require('../assets/voices/fire.wav')
    this.audioObj.loop = 'loop'

    this.curRouter = this.$route.path
    this.pwdFormCopy = JSON.stringify(this.pwdForm)
    this.menuList = JSON.parse(sessionStorage.getItem('menu'))
    if (this.menuList) {
      this.firstMentList = this.menuList.slice(0, 4)
      this.secondMentList = this.menuList.slice(4, this.menuList.length)
    }
    if (sessionStorage.getItem('tabList')) {
      this.tabsList = JSON.parse(sessionStorage.getItem('tabList'))
    }
    if (sessionStorage.getItem('activePath')) {
      this.activePath = JSON.parse(sessionStorage.getItem('activePath'))
    }
    this.areaCode = sessionStorage.getItem('areaCode')
    if (this.areaCode === '360803900') {
      this.homeTitle = '贸易广场消防物业管理平台'
    }
    else {
      this.homeTitle = '捷信云物联网大数据服务平台'
    }

    // this.getAlarmTotal()
    // this.timeMachine = setInterval(() => {
    //   this.getAlarmTotal()
    // }, 5000)

    if (sessionStorage.getItem('submenu')) {
      this.menuList2 = JSON.parse(sessionStorage.getItem('submenu'))
    }
    else {
      if (this.menuList[0].children) {
        this.menuList2 = this.menuList[0].children
      }
    }
    this.roleName = sessionStorage.getItem('roleName')
    this.roleId = parseInt(sessionStorage.getItem('roleId'))
    this.userName = sessionStorage.getItem('username')
    this.clock = setInterval(this.updateTime, 1000)
    this.changeRoute()
  },
  methods: {
    // 退出登录
    async logout() {
      const confirm = await this.$confirm('您确定要退出登陆?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        sessionStorage.clear()
        this.$router.push('/')
      }
    },
    // 点击菜单事件
    initMenu(index, item) {
      this.currentMenu = item.path
      if (item.path) {
        this.cacheTabList(item)
        if (item.path === '/home/unit/info') {
          this.$router.push('/home/unit/info/base')
        }
        else {
          this.$router.push(item.path)
          sessionStorage.setItem('btnRole', JSON.stringify(item.perms))
        }
      }
      else {
        this.menuList2 = item.children
        sessionStorage.setItem('submenu', JSON.stringify(this.menuList2))
        if (!this.menuList2[0].children) {
          this.cacheTabList(this.menuList2[0])
          this.$router.push(this.menuList2[0].path)
          sessionStorage.setItem('btnRole', JSON.stringify(this.menuList2[0].perms))
        }
        else {
          this.cacheTabList(this.menuList2[0].children[0])
          this.$router.push(this.menuList2[0].children[0].path)
          sessionStorage.setItem('btnRole', JSON.stringify(this.menuList2[0].children[0].perms))
        }
      }
    },
    onGetMenu(item) {
      this.cacheTabList(item)
      this.$router.push({
        path: item.path
      })
      sessionStorage.setItem('path', item.path)
      sessionStorage.setItem('btnRole', JSON.stringify(item.perms))
    },
    // 缓存菜单
    cacheTabList(item) {
      if (!this.judgeTab(item)) {
        if (item.id !== this.menuList[0].id) {
          this.tabsList.push(item)
          sessionStorage.setItem('tabList', JSON.stringify(this.tabsList))
        }
      }
      this.activePath = item.path
      sessionStorage.setItem('activePath', JSON.stringify(this.activePath))
    },
    // 判断tabList是否存在某菜单
    judgeTab(item) {
      const array = []
      for (let i = 0; i < this.tabsList.length; i++) {
        array.push(this.tabsList[i].id)
      }
      return _.includes(array, item.id)
    },
    // 修改密码
    async updatePassword() {
      const {data: result} = await this.$axios.put(`/user/editPassword/${sessionStorage.getItem('userId')}`, this.pwdForm)
      if (result.code === 200) {
        sessionStorage.clear()
        this.$message.success('修改成功,请重新登陆')
        this.$router.push('/')
      }
      else {
        this.$message.error(result.msg)
      }
    },
    // 更改时间
    updateTime() {
      var cd = new Date()
      this.time = this.zeroPadding(cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2)
      this.date = this.zeroPadding(cd.getFullYear(), 4) + '/' + this.zeroPadding(cd.getMonth() + 1, 2) + '/' + this.zeroPadding(cd.getDate(), 2) + ' ' + this.week[cd.getDay()]
    },
    zeroPadding(num, digit) {
      var zero = ''
      for (var i = 0; i < digit; i++) {
        zero += '0'
      }
      return (zero + num).slice(-digit)
    },
    // 清空表单
    clear() {
      this.pwdForm = JSON.parse(this.pwdFormCopy)
      this.$refs.pwdForm.clearValidate()
    },
    // 变换路由
    changeRoute() {
      this.activePath = this.$route.path
      sessionStorage.setItem('activePath', JSON.stringify(this.activePath))
      if (this.$route.path === '/home/GIS' || this.$route.path === '/home/camera' || this.$route.path === '/home/library' ||
        this.$route.path === '/home/building' || this.$route.path.indexOf('/home/unit/info') !== -1 ||
        this.$route.path === '/home/unit/water' || this.$route.path === '/home/unit/device' || this.$route.path === '/home/unit/home' || this.$route.path === '/home/unit/record') {
        if (this.$route.path === '/home/library' || this.$route.path === '/home/building' || this.$route.path.indexOf('/home/unit/info') !== -1 ||
          this.$route.path === '/home/unit/water' || this.$route.path === '/home/unit/device' || this.$route.path === '/home/unit/home' || this.$route.path === '/home/unit/record') {
          this.isTable = true
        }
        else {
          this.isTable = false
        }
        this.show = false
      }
      else {
        this.show = true
      }
    },
    // 监听tabs标签的鼠标右键事件
    openMenu(path, index, e) {
      this.activeIndex = index
      if (this.activePath !== path) {
        this.$router.push(path)
      }
      document.querySelector('#tabs_item' + index).scrollIntoView(true)
      const menuMinWidth = 160
      const offsetLeft = this.$el.getBoundingClientRect().left
      const offsetWidth = this.$el.offsetWidth
      const maxLeft = offsetWidth - menuMinWidth
      const left = e.clientX - offsetLeft
      if (left > maxLeft) {
        this.MenuLeft = maxLeft + 10
      }
      else {
        this.MenuLeft = left + 10
      }
      this.MenuTop = e.clientY
      this.visible = true
    },
    // 标签跳转
    toPage(path, index) {
      this.activePath = path
      // 防止重复点击同一路劲产生报错
      this.$router.push({path: path}, onComplete => {
      }, onAbort => {
      })
      document.querySelector('#tabs_item' + index).scrollIntoView(true)
    },
    // 关闭图标和菜单的标签事件
    closeEvent(flag, data) {
      switch (flag) {
        case 'self-close':
          // 关闭图标关闭自己
          for (let index = 0; index < this.tabsList.length; index++) {
            if (index === data) {
              if (index === 0 && this.tabsList.length !== 1) {
                if (index === data) {
                  this.$router.push(this.tabsList[index + 1].path)
                  this.activeIndex = index + 1
                }
              }
              else if (this.tabsList.length === 1) {
                if (this.roleId === 12) {
                  this.$router.push('/home/unit/home')
                  this.activeIndex = -1
                }
                else {
                  this.$router.push('/home/GIS')
                  this.activeIndex = -1
                }
              }
              else {
                if (index - 1 === 0 || data === this.tabsList.length - 1) {
                  this.$router.push(this.tabsList[index - 1].path)
                  this.activeIndex = index - 1
                }
              }
              this.tabsList.splice(index, 1)
              break
            }
          }
          break
        case 'self':
          // 关闭自己
          this.tabsList.splice(this.activeIndex, 1)
          this.activeIndex--
          const item = this.tabsList[this.activeIndex]
          const path = item === undefined ? '/home/GIS' : item.path
          this.$router.replace(path)
          // for (let index = 0; index < this.tabsList.length; index++) {
          //   if (index === this.activeIndex) {
          //     if (index === 0 && this.tabsList.length !== 1) {
          //       this.$router.push(this.tabsList[index + 1].path)
          //       this.activeIndex = index + 1
          //     } else if (this.tabsList.length === 1) {
          //       this.$router.push('/')
          //       this.activeIndex = -1
          //     } else {
          //       this.$router.push(this.tabsList[index - 1].path)
          //       this.activeIndex = index - 1
          //     }
          //     this.tabsList.splice(index, 1)
          //     break
          //   }
          // }
          break
        case 'left':
          // 关闭到左侧
          for (let index = 0; index < this.activeIndex; index++) {
            this.tabsList.splice(0, 1)
          }
          break
        case 'right':
          // 关闭到右侧
          for (let index = this.tabsList.length - 1; index > this.activeIndex; index--) {
            this.tabsList.splice(this.tabsList.length - 1, 1)
          }
          break
        case 'other':
          // 关闭其它
          var obj = this.tabsList[this.activeIndex]
          this.tabsList = []
          this.tabsList.push(obj)
          break
        case 'all':
          // 全部关闭
          this.tabsList = []
          this.$router.push('/home/GIS')
          this.activeIndex = -1
          break
        default:
          break
      }
      sessionStorage.setItem('tabList', JSON.stringify(this.tabsList))
    },
    // 控制tabs标签栏左右滚动
    arrow(flag) {
      const moveLength = 120
      const offset = this.$refs.tabs.scrollLeft
      if (flag === 'add') {
        this.$refs.tabs.scrollLeft = offset + moveLength
      }
      else if (flag === 'ins') {
        this.$refs.tabs.scrollLeft = offset - moveLength
      }
    }
  }
}
</script>

<style lang="scss">
.wrap {
  position: relative;

  .home-header {
    color: #39b8e6;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 76px !important;
    font-size: 18px;
    padding: 0 1%;
    background: url("../assets/img/bg-header.png") no-repeat center center;
    background-size: 100% 100%;

    .home-title {
      height: 100%;
      width: 25%;
      padding: 0 0.1%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: 100% 100%;
      color: #fff;
      font-size: 20px;
      text-shadow: 4px 6px 6px #14182d;
      font-weight: 600;
      transform: translateY(-10px);

      span {
        letter-spacing: 2px;
        margin-left: 5px;
      }
    }

    .header-menu {
      display: flex;
      flex-direction: column;
      height: 70%;
      width: 30%;
      position: relative;

      .el-menu {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        background-color: transparent !important;
        border-bottom: none !important;

        .el-menu-item {
          height: 60%;
          color: #39b8e6 !important;
          display: flex;
          align-items: center;
          justify-content: center;
          background: url("../assets/img/menu-bg.png") no-repeat center center;
          background-size: 100% 100%;

          i {
            color: #39b8e6 !important;
          }
        }

        //设置选中el-menu-item时的ff2556样式
        .el-menu-item.is-active {
          background: linear-gradient(
              180deg,
              rgba(60, 192, 241, 0) 0%,
              rgba(60, 192, 241, 0.24) 100%
          ) !important;
          border-bottom: 3.5px solid #409eff !important;
          box-sizing: border-box;
          color: #39b8e6 !important;

          i {
            color: #39b8e6 !important;
          }
        }

        //设置鼠标悬停时el-menu-item的样式
        .el-menu-item:hover {
          background: linear-gradient(
              180deg,
              rgba(60, 192, 241, 0) 0%,
              rgba(60, 192, 241, 0.24) 100%
          ) !important;
          color: #39b8e6 !important;

          i {
            color: #39b8e6 !important;
          }
        }

        .el-menu-item:focus {
          background: linear-gradient(
              180deg,
              rgba(60, 192, 241, 0) 0%,
              rgba(60, 192, 241, 0.24) 100%
          ) !important;
          color: #39b8e6 !important;

          i {
            color: #39b8e6 !important;
          }
        }
      }
    }

    .header-menu:first-child {
      .el-menu {
        justify-content: flex-start;

        .el-menu-item {
          margin-left: 20px;
        }
      }
    }

    .header-menu:nth-child(3) {
      .el-menu {
        justify-content: flex-end;

        .el-menu-item {
          margin-right: 20px;
        }
      }
    }

    .el-avatar {
      border: 1.5px solid #2ca3ce;
    }

    .el-dropdown {
      display: flex;
      align-items: center;
      height: 40px;
      position: absolute;
      right: 0;
      top: 0;

      .el-avatar {
        vertical-align: middle;
        cursor: pointer;
      }
    }

    #clock {
      color: #ffffff;
      text-align: center;
      position: absolute;
      left: 75%;
      top: 20px;
      font-size: 14px;

      .time {
        margin-left: 5px;
        margin-right: 15px;
      }
    }
  }

  .bottom {
    padding: 0 1% 1% 1%;
    box-sizing: border-box;
    background: url("../assets/img/bg-body.png") no-repeat center center;
    background-size: 100% 100%;

    .home-aside {
      overflow: hidden;
      background: rgba(16, 29, 53, 0.3);
      transition: all ease 0.3s;

      .toggle {
        font-size: 18px;
        text-align: center;
        height: 60px;
        line-height: 60px;
        background-color: transparent;
        cursor: pointer;
        transition: all ease 0.3s;
        color: #39b8e6;
      }

      .toggle:hover {
        background: linear-gradient(
            90deg,
            rgba(110, 226, 249, 0) 0%,
            rgba(57, 184, 230, 0.2) 100%
        ) !important;
      }

      .el-menu {
        background-color: transparent !important;
        border-right: 0;

        i {
          color: #39b8e6;
        }

        //设置鼠标悬停时el-menu-item的样式
        .el-menu-item:hover {
          // border-left: #33a2ef solid 6px !important;
          background: linear-gradient(
              90deg,
              rgba(110, 226, 249, 0) 0%,
              rgba(57, 184, 230, 0.2) 100%
          ) !important;
          color: #39b8e6;
          // color: #38b2ff !important;
          //less语法，实现鼠标悬停时icon变色
        }

        .el-menu-item:focus {
          background: linear-gradient(
              90deg,
              rgba(110, 226, 249, 0) 0%,
              rgba(57, 184, 230, 0.2) 100%
          ) !important;
          color: #39b8e6;
        }

        //设置选中el-menu-item时的ff2556样式
        .el-menu-item.is-active {
          // border-left: #33a2ef solid 6px !important;
          background: linear-gradient(
              90deg,
              rgba(110, 226, 249, 0) 0%,
              rgba(57, 184, 230, 0.2) 100%
          ) !important;
          color: #39b8e6;
          // color: #38b2ff !important;
        }

        .el-submenu__title {
          color: #39b8e6;
          text-align: left;
        }

        .el-submenu__title:hover {
          background: linear-gradient(
              90deg,
              rgba(110, 226, 249, 0) 0%,
              rgba(57, 184, 230, 0.2) 100%
          ) !important;
        }

        .el-menu-item {
          color: #39b8e6;
          text-align: left;
        }
      }
    }

    .tabs_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 15px;
      overflow: hidden;
      margin-top: 10px;

      .arrow {
        z-index: 1;
        cursor: pointer;
        height: 30px;
        width: 20px;
        box-sizing: border-box;
        display: flex;
        font-size: 18px;
        align-items: center;
        justify-content: center;
        color: #42affe;
      }

      .tabs {
        flex: 10;
        z-index: 0;
        display: flex;
        transition: 0.5s all;
        align-items: center;
        justify-content: flex-start;
        overflow-x: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          display: none;
        }

        .tabs_item {
          flex-shrink: 0;
          cursor: pointer;
          overflow: hidden;
          font-size: 12px;
          padding: 7px 15px;
          display: flex;
          color: #fff;
          box-sizing: border-box;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          border: none;
          background-image: url("../assets/img/tab-bg.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          margin-left: 10px;

          .close {
            box-sizing: border-box;
            margin-left: 5px;
            border-radius: 100%;
          }
        }

        .active {
          background: rgba(60, 192, 241, 0.24) !important;
          color: #39b8e6 !important;
        }
      }

      .menu_box {
        width: 150px;
        display: flex;
        position: fixed;
        z-index: 9999;
        align-items: flex-start;
        flex-direction: column;
        align-content: flex-start;
        justify-content: space-around;
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);

        .menu {
          cursor: pointer;
          width: 100%;
          box-sizing: border-box;
          padding: 10px;
          display: flex;
          color: #303133;
          font-size: 12px;
          align-items: center;
          justify-content: flex-start;
          border-bottom: 1px solid #f2f2f2;

          &:last-child {
            border-bottom: none;
          }

          &:hover {
            background: #f5f5f5;
          }
        }

        .disabled {
          cursor: not-allowed;
          background-color: #f5f5f5;
          color: #ccc;
        }
      }
    }

    .el-main {
      color: #fff;
      text-align: center;
      font-family: "Helvetica Neue", Helvetica, "PingFang SC",
      "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
      display: flex;
      flex-direction: column;
      height: calc(100% - 40px);
      padding: 10px;

      .container {
        height: 100%;
        width: 100%;
        padding: 0 10px 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        background-image: url("../assets/img/table-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .header {
          flex: 1;
          display: flex;
          align-items: center;
          height: 40px;
          justify-content: flex-start;
          flex-wrap: wrap;

          .el-form {
            margin-left: 10px;
            height: 40px;

            .el-form-item__label {
              color: #fff;
            }

            .el-input__inner {
              background-color: rgba(7, 104, 159, 0.15);
              color: #fff;

              .el-range-input {
                background-color: transparent;
                color: #fff;
              }
            }
          }

          .el-button {
            margin-right: auto;
          }

          .el-button--primary {
            background: #03236c;
            border: 1px solid #ffffff;
            border-radius: 6px;
            color: #3b86f9;
          }

          .el-button--success {
            background: #03236c;
            border: 1px solid #ffffff;
            border-radius: 6px;
            color: #50c48f;
          }

          .el-button--warning {
            background: #03236c;
            border: 1px solid #ffffff;
            border-radius: 6px;
            color: #f7b13f;
          }

          .el-button--default {
            background: #03236c;
            border: 1px solid #ffffff;
            border-radius: 6px;
            color: #dedede;
          }
        }

        .content {
          flex: 9;
          overflow: hidden;

          .el-table,
          .el-table__expanded-cell {
            background-color: transparent;

            tr,
            th {
              background-color: transparent;
              color: #fff;

              .el-table__expand-icon {
                color: #fff;
              }
            }

            .el-table--enable-row-hover,
            .hover-row > td,
            tr:hover > td {
              background-color: rgba(28, 97, 160, 0.8) !important;
            }

            .el-table__row--striped > td {
              background-color: rgba(44, 74, 130, 0.2) !important;
            }

            .current-row > td {
              background-color: rgba(7, 104, 159, 0.5) !important;
            }
          }

          .zk-table {
            background-color: transparent;
            color: #fff;

            .zk-table__header-row {
              background-color: transparent;
              color: #fff;
            }

            .zk-table--row-hover:hover {
              background-color: rgba(28, 97, 160, 0.8);
            }

            .zk-table--stripe-row {
              background-color: rgba(44, 74, 130, 0.2);
            }
          }
        }

        .footer {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          .el-pagination {
            color: #fff;
            background-color: transparent;

            .el-pagination__total,
            .el-pagination__jump {
              color: #fff;
            }

            .btn-prev,
            .btn-next {
              background-color: transparent;
              color: #fff;
            }

            .el-pager li {
              background-color: transparent !important;
            }

            .btn-quicknext {
              color: #fff;
            }
          }
        }
      }

      .el-drawer {
        background-color: transparent !important;

        .el-drawer__header {
          background-image: url("../assets/img/dialog-header.png") !important;
          color: #fff !important;
          text-align: left;
          margin-bottom: 0;

          .el-dialog__close {
            font-size: 18px;
            color: #ffb32f;
          }
        }

        .el-drawer__body {
          background-image: url("../assets/img/dialog-body.png") !important;

          .container {
            background-image: none;
          }

          .el-textarea__inner {
            background-color: rgba(7, 104, 159, 0.15);
            color: #fff;
          }
        }

        .el-drawer__header,
        .el-drawer__body {
          background-repeat: no-repeat !important;
          background-size: 100% 100% !important;
        }
      }
    }
  }

  .footer-wrap {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    color: #fff;
    opacity: 0.6;

    & > * {
      margin-left: 10px;
    }

    a {
      font-size: 12px;
      color: #fff;
    }

    //span:last-child {
    //  margin-left: 10px;
    //}
  }
}

/*在Chrome下移除input[number]的上下箭头*/
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none !important;
}

/*在firefox下移除input[number]的上下箭头*/

input[type="number"] {
  -moz-appearance: textfield;
}

.el-dialog {
  background-color: transparent !important;

  .el-dialog__header {
    background-image: url("../assets/img/dialog-header.png") !important;
    text-align: left;
    padding: 20px 35px 10px;

    .el-dialog__title {
      color: #3debff;
    }

    .el-dialog__headerbtn {
      top: 40px;
      font-size: 18px;

      i {
        color: #ffb32f;
      }
    }
  }

  .el-dialog__body {
    background-image: url("../assets/img/dialog-body.png") !important;

    .el-button--default {
      background: rgba(85, 110, 183, 0.2);
      border: none;
      border-radius: 6px;
      color: #dedede;
    }

    .el-button--primary {
      background: #03236c;
      border: 1px solid #3debff;
      border-radius: 5px;
      color: #dedede;
    }

    .container {
      background: transparent;
    }
  }

  .el-dialog__body,
  .el-dialog__header {
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
  }

  .el-dialog__body,
  .el-dialog__footer {
    .el-form {
      .el-form-item__label {
        color: #fff;
      }

      .el-input__inner,
      .el-textarea__inner,
      .el-input-number__decrease,
      .el-input-number__increase,
      .el-radio__label,
      .el-upload--picture-card {
        background-color: rgba(7, 104, 159, 0.15);
        color: #fff;

        .el-range-input {
          background-color: transparent;
          color: #fff;
        }
      }

      .el-input__count,
      .el-input__count-inner {
        background-color: transparent;
        color: #fff;
      }
    }

    // .dialog-footer {
    //   width: 100%;
    //   display: flex;
    //   justify-content: flex-end;
    // }
  }
}

.alarm-badge {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #409eff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.alarm-badge:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}
</style>
