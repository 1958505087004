<template>
  <el-menu :collapse="isCollapse" mode="vertical" :default-active="$route.path" :unique-opened="true">
    <template v-for="(item, key) in menuList">
      <el-submenu :index="item.text" v-if="item.children && item.children.length > 0" :key="key">
        <template slot="title">
          <i :class="item.icon"></i>
          <span>{{ item.text }}</span>
        </template>
        <SubItem :chil="item.children" :props="props" @getSubItem="onMenuItemClick" />
      </el-submenu>
      <el-menu-item :index="item.text" v-else @click="onMenuItemClick(item)" :key="key">
        <i :class="item.icon"></i>
        <span slot="title">{{ item.text }}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
import SubItem from './SubItem'
export default {
  name: 'subMenu',
  components: { SubItem },
  props: {
    menuList: {
      type: Array,
      default () {
        return []
      }
    },
    props: {
      type: Object,
      default () {
        return {
          children: 'children',
          text: 'label',
          id: 'id'
        }
      }
    },
    isCollapse: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onMenuItemClick (item) {
      this.$emit('getmenu', item)
    }
  }
}
</script>
