<template>
  <div>
    <template v-for="(item, key) in chil">
      <el-submenu :index="item.text" :key="key" v-if="item.children && item.children.length > 0">
        <template slot="title">
          <i :class="item.icon"></i>
          <span>{{ item.text }}</span>
        </template>
        <sub-item :chil="item.children" :props="props" @getSubItem="onSubTtemClick" />
      </el-submenu>
      <el-menu-item :index="item.text" v-else :key="key" @click="onSubTtemClick(item)">
        <i :class="item.icon"></i>
        <span slot="title">{{ item.text }}</span>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
export default {
  name: 'subItem',
  props: {
    chil: {
      type: Array,
      default () {
        return []
      }
    },
    props: {
      type: Object,
      default () {
        return {
          children: 'children',
          text: 'label',
          id: 'id'
        }
      }
    }
  },
  methods: {
    onSubTtemClick (item) {
      this.$emit('getSubItem', item)
    }
  }
}
</script>
